import { Injectable, Type } from '@angular/core';
import { Modal, ModalActions, ModalActionType } from 'components/modal/modal.model';
import * as _ from 'lodash';

import { environment } from 'environments/environment';
import { DomService } from 'weavix-shared/services/dom.service';
import { ModalComponent } from 'components/modal/modal.component';
import { AbstractControl } from '@angular/forms';
import { Utils } from 'weavix-shared/utils/utils';

export * from 'components/modal/modal.model';

export interface ModalResult {
    action: ModalActionType;
    data?: any;
}

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    private forms = new Map<any, AbstractControl>();

    constructor(private domService: DomService) { }

    canLeave(exclude?: any) {
        return ![...this.forms.entries()].some(([component, form]) => component !== exclude && form.dirty);
    }

    registerForm(component: any, form: AbstractControl) {
        this.forms.set(component, form);
        if (component) Utils.safeDispose(component, () => this.deregisterForm(component));
    }

    deregisterForm(component: any) {
        this.forms.delete(component);
    }

    async confirmLeave() {
        const result = await this.confirm('generics.leaveConfirm', 'generics.unsaved-changes', {
            [ModalActionType.submit]: { textKey: 'generics.leave', buttonClass: 'blue-lt' },
        }, true);
        return result.action === ModalActionType.submit;
    }

    async confirmDelete(message?: string): Promise<ModalResult> {
        message = message || 'generics.confirmPrompt';
        return await this.confirm(message, 'generics.delete', {
            [ModalActionType.submit]: { textKey: 'generics.delete', buttonClass: 'red' },
        }, true);
    }

    async confirm(textKey: string | undefined, headerTextKey: string, actions?: ModalActions, fullScreen?: boolean, params: any = {}): Promise<ModalResult> {
        return new Promise((resolve) => {
            const modalInput: Modal = this.basicModalConfig(textKey, headerTextKey, fullScreen, params);

            modalInput.actions = _.merge(modalInput.actions, actions);

            this.injectModal<ModalComponent>(ModalComponent, (c) => {
                c.modalInput = modalInput;
                c.modalCloseOutput.subscribe(action => {
                    this.destroyModal();
                    resolve({ action });
                });
            });
        });
    }

    private basicModalConfig(textKey?: string, headerTextKey?: string, fullScreen?: boolean, params?: any) {
        const modalInput: Modal = {
            textKey,
            header: {
                textKey: headerTextKey,
                textAlignment: environment.teamsApp ? 'left' : 'center',
            },
            isOpen: true,
            width: 600,
            actions: {
                [ModalActionType.submit]:  { show: true },
                [ModalActionType.cancel]:  { show: true },
            },
            fullScreen,
            params,
            zIndex: 1100,
        };
        return modalInput;
    }

    private injectModal<T>(component: Type<T>, manageComponent: (c: T) => void) {
        // TODO: we should use Angular CDK Portal or Overlay instead.
        this.domService.appendComponentTo(component, manageComponent);
    }

    private destroyModal() {
        this.domService.removeComponent();
    }
}
