<div class="wrapper" [ngClass]="{'light': lightTheme }">
    <div *ngIf="(tableService.loading$ | async) === false" class="content-wrapper">
        <ng-container>
            <app-filter-header
                [titleKey]="titleKey"
            ></app-filter-header>

            <div class="scroll-wrapper filter-results">

                <ng-container *ngIf="filterResultsToDisplay?.length && hasDateRangeFilter">
                    <div class="res-item flexRow jcFS aiC"
                        [ngClass]="{'selected': dateRangeFilter.selected, 'disabled': dateRangeFilter?.disabled && dateRangeFilter?.disabled.call(dateRangeFilter)}"
                        (click)="handleDateRangeFilterClick()">
                        <div class="res-item-icon">
                            <app-icon [ngClass]="{'light': lightTheme}" [faIcon]="dateRangeFilter.selected ? 'fa fa-minus' : 'fa fa-plus'"></app-icon>
                        </div>
                        <app-icon class="res-item-cat-icon mx-2" [ngClass]="{'light': lightTheme}" faIcon="fa-solid fa-calendar-days"></app-icon>
                        <div class="res-item-text" [ngClass]="{'light': lightTheme}">{{ 'generics.dates.submitted' | translate }}</div>
                    </div>
                    <div *ngIf="dateRangeFilter.selected" class="children">
                        <app-date-range-picker
                            [(ngModel)]="selectedDateRange"
                            [includeTimeRange]="false"
                            [disabled]="false"
                            (dateRangeChanged)="handleDateRangeFilterChange($event)"
                        ></app-date-range-picker>
                    </div>
                </ng-container>

                <ng-container *ngFor="let res of filterResultsToDisplay">
                    <ng-container *ngTemplateOutlet="resItem; context: {parent: {children: filterResults}, res: res, resIndex: 0}"></ng-container>
                </ng-container>
            </div>
        </ng-container>

        <div class="actions">
            <button class="clear-filters" (click)="handleClearFilters()">
                {{ 'table.clear-filters' | translate }}
            </button>
        </div>
    </div>
</div>

<ng-template #resItem let-res="res" let-resIndex="resIndex" let-parent="parent">
    <app-table-filter-row [result]="res" [index]="resIndex" (selectEmit)="handleRowClick(parent, $event, false, resIndex)" (clickEmit)="handleRowClick(parent, $event, true, resIndex)"></app-table-filter-row>

    <ng-container *ngIf="res?.children && res.selected">
        <ng-container *ngFor="let child of getChildren(res)">
            <div class="children">
                <ng-container *ngTemplateOutlet="resItem; context: {parent: res, res: child, resIndex: resIndex + (res.depth ? 2 : 1)}"></ng-container>
            </div>
        </ng-container>
    </ng-container>
</ng-template>
