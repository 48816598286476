import { PersonMatcher, SimplePersonCondition } from '../person/person-matcher';

export enum ChannelAlertsType {
    sms = 'sms',
    badge = 'badge',
    facilityOnly = 'facilityOnly',
    soundMuted = 'soundMuted',
    notificationsOff = 'notificationsOff',
    allowMuting = 'allowMuting',
    allowDisablingNotifications = 'allowDisablingNotifications',
    highPriorityChannel = 'highPriorityChannel',
}

export interface CommunicationPreferences {
    allowNotifications?: boolean;
    allowPtt?: boolean;
    snoozeNotifications?: boolean;
}

export type ChannelNotifications = CommunicationPreferences & Partial<Record<ChannelAlertsType, boolean>>;

export interface ConditionalChannelNotificationType extends ChannelNotifications, PersonMatcher {
    description: string;
    // legacy storage of conditions list
    conditions?: SimplePersonCondition[];
}

export interface ChannelPreference {
    id?: { channelId: string; userId?: string; };
    favoriteIndex?: number;
    isSnoozed?: boolean;
    readSequence?: number;
    notification?: ChannelNotifications;
    updated?: Date;
}

export interface ChannelNotify {
    favoriteIndex?: number;
    isSnoozed?: boolean;
    notification?: ChannelNotifications;

    /**
     * true if the user wishes to mark the channel as read
     */
    read?: boolean;

    /**
     * The number of messages the user has read if the user has not read the entire channel.
     * Not used anymore.
     */
    readCount?: number;
}


export interface ChannelSelfCorrection {
    /**
     * The sequence number of the last message the user has read. This could be
     * greater than the sequence number of the channel itself.
     * 
     * This is a fallback in case the channel sequence and channel message sequences
     * are not in sync. Ideally this should not be needed, but there have been cases
     * where the channel sequence gets behind the channel message sequences, causing
     * users to see unread messages that they cannot mark as read.
     * 
     * It's possible that the bug causing this is already fixed.
     */
    readSequence?: number;
}
