import { Injectable } from '@angular/core';
import { IndexedStorage, SyncMqtt, SyncService } from '@weavix/sync';
import { SyncHttp } from '@weavix/sync/src/sync.model';
import { HttpService } from '../http.service';
import { PubSubService } from '../pub-sub.service';
import { WebNativeStorage } from './web-native-storage';
import { RootStoreService } from './root-store-service';
import { environmentContext } from '../../../models-mobx/environment';
import { myUserId } from '../../../models-mobx/my-profile-store/my-profile-store';
import { TelemetryEvent } from '@weavix/models/src/telemetry';
import { Topic } from '@weavix/models/src/topic/topic';

@Injectable({
    providedIn: 'root',
})
export class SyncNgService {
    private constructor(
        httpService: HttpService,
        private pubSubService: PubSubService,
        private rootStoreService: RootStoreService,
    ) {
        environmentContext.setDefault({ pubSubService });
        const syncMqtt: SyncMqtt = {
            subscribe: topic => pubSubService.subscribe(null, topic as Topic, []),
            // Web apps dont do simulcasting
            subscribeMultiple: topic => pubSubService.subscribe(null, topic as Topic, []),
            sendTelemetry: (name: TelemetryEvent, data) => pubSubService.publish(`self/${myUserId()}/telemetry` as Topic, [], [{ name, data }]),
        };
        const syncHttp: SyncHttp = {
            get: (path, params) => httpService.get(null, path, params),
            post: (path, body) => httpService.post(null, path, body),
        };
        const storage = new IndexedStorage<any>(new WebNativeStorage(`sync-${SyncService.version}`));
        SyncService.instance = new SyncService(syncHttp, syncMqtt, storage);
    }

    public async onLoggedIn(): Promise<void> {
        const wasLoggedIn = await SyncService.instance.loggedIn();
        this.reportTelemetry(wasLoggedIn);
        await this.rootStoreService.getRootStore().wait();
        if (!wasLoggedIn) {
            await SyncService.instance.waitForModules();
            await this.rootStoreService.getRootStore().wait();
        }
    }

    private async reportTelemetry(wasLoggedIn: boolean) {
        const start = new Date().getTime();
        const metrics = await SyncService.instance.waitForModules();

        const event = {
            name: TelemetryEvent.SyncStartup,
            data: {
                platform: 'web',
                userId: myUserId(),
                duration: new Date().getTime() - start,
                login: !wasLoggedIn,
                ...metrics,
            },
        };
        this.pubSubService.publish(`user/${myUserId()}/telemetry` as Topic, [], [event]);
    }

    public async onLoggedOut(): Promise<void> {
        await SyncService.instance.loggedOut(true);
    }
}
