<button *ngIf="options?.showGoBackButton"
    id="back-button"
    type="button"
    class="btn standard-width gray-lt config-form-button spaced-left"
    (click)="backOutput.emit()">

    {{ 'generics.go-back' | translate }}
</button>

<button *ngIf="options?.actionButton"
    type="button"
    class="btn standard-width gray-lt config-form-button spaced-left"
    [ngClass]="options.actionButton.class"
    (click)="options.actionButton.action()">
    
    {{ options.actionButton.textKey | translate }}
</button>

<button *ngIf="options?.showDeleteButton"
    id="delete-button"
    type="button" 
    class="btn standard-width gray-lt config-form-button asFE spaced-left"
    (click)="deleteOutput.emit()">

    {{ (options?.deleteTextKey ? options.deleteTextKey : 'generics.delete') | translate }}
</button>

<button *ngIf="options?.showCancelButton"
    id="cancel-button"
    type="button" 
    class="btn standard-width gray-lt config-form-button asFE"
    (click)="cancelOutput.emit()">

    {{ 'generics.cancel' | translate }}
</button>

<button
    *ngIf="options?.showSubmitButton"
    id="submit-button"
    [type]="options?.enterSubmits ? 'submit' : 'button'"
    class="btn standard-width blue-lt config-form-button spaced-right"
    [ngClass]="{ 'submit-with-dropdown': options?.submitButtonDropdown && options.submitButtonDropdown.length > 0 }"
    [disabled]="options?.submitDisabled"
    (click)="submitOutput.emit()"
>
        
    <span 
        [ngClass]="{ 'button-text-span': options?.submitButtonDropdown && options.submitButtonDropdown.length > 0 }"
    >
        {{ (options?.submitTextKey ? options.submitTextKey : 'generics.save') | translate }}
    </span>
    <button
        *ngIf="options?.submitButtonDropdown && options.submitButtonDropdown.length > 0"
        [matMenuTriggerFor]="menu"
        #menuOpened="matMenuTrigger"
        (click)="$event.stopPropagation()"
        [disabled]="options?.submitDisabled"
        class="chevron-button"
        [ngClass]="{ 'opened': menuOpened.menuOpen }"
    >
        <app-icon *ngIf="!menuOpened.menuOpen" faIcon="fas fa-chevron-down" class="chevron-icon"></app-icon>
        <app-icon *ngIf="menuOpened.menuOpen" faIcon="fas fa-chevron-up" class="chevron-icon"></app-icon>
    </button>
</button>

<mat-menu id="submit-options-menu" #menu="matMenu" xPosition="before" yPosition="below" class="submit-options-menu">
    <button mat-menu-item *ngFor="let item of options.submitButtonDropdown" (click)="item.action()">
        {{ item.textKey | translate }}
    </button>
</mat-menu>
