import { SyncEventStorage } from '../sync.model';
import { LazyStorage } from './lazy-storage';
import { NativeStorage } from './native-storage';
import { max, maxBy } from 'lodash';
import { IndexedStorage } from './indexed-storage';

// on application start, server returns previous 60 seconds worth of sync events
// this makes sure if we missed any while the app was shutting down we get them here
// so we track the ones we have run to not run the extras it sends back
// also mqtt does QoS 1, so we could receive the same update again on reconnection, as well as on start
const PRUNE_SYNC_MIN_MS = 120_000;

export class PartitionEventStorage implements SyncEventStorage {
    syncIds = new Set<string>();
    pruneTime = 0;

    async getPartitions(): Promise<string[]> {
        return Object.keys(await this.partitionEvents.getMap());
    }

    // Keeps track of latest partition events to avoid duplicates and for querying for changes
    private partitionEvents: LazyStorage<Array<{ date: string; syncId: string; }>>;

    constructor(
        private storage: NativeStorage,
        private indexedStorage: IndexedStorage<any>
    ) {
        this.partitionEvents = new LazyStorage(this.storage, 'partition-dates');
    }

    destroy() {
        this.partitionEvents.destroy();
    }

    async clear(partition: string, data?: any[]) {
        this.syncIds.clear();
        this.partitionEvents.hold();
        if (data) {
            this.partitionEvents.set(partition, []);
            await this.indexedStorage.clear(partition, data);
        } else {
            this.partitionEvents.remove(partition);
            await this.indexedStorage.removePartition(partition);
        }
        this.partitionEvents.unhold();
    }

    // gets the date of the latest sync event executed
    async getDate(partition: string) {
        const events = await this.partitionEvents.get(partition);
        if (!events) this.partitionEvents.set(partition, []);
        if (events?.length) this.updatePruneDate(events);
        events?.forEach(event => {
            if (event.syncId) this.syncIds.add(event.syncId);
        });
        const date = max((events ?? []).map(v => v.date));
        return date;
    }

    isDuplicate(date: string, syncId: string): boolean {
        return this.syncIds.has(syncId) || new Date(date).getTime() <= this.pruneTime;
    }

    // adds a give date / syncId to the storage to keep track of the latest event run
    add(partition: string, date: string, syncId: string) {
        this.partitionEvents.set(partition, val => {
            if (syncId) this.syncIds.add(syncId);
            val.push({ date, syncId });

            // only prune when double to avoid pruning the array constantly
            if (new Date(val[0].date).getTime() + PRUNE_SYNC_MIN_MS * 2 < new Date(date).getTime()) {
                this.updatePruneDate(val);
                val = val.filter(x => {
                    // keep anything at least 2 minutes from the latest date
                    if (new Date(x.date).getTime() >= this.pruneTime) return true;
                    this.syncIds.delete(x.syncId);
                    return false;
                });
            }
            return val;
        });
    }

    private updatePruneDate(events: Array<{ date: string; syncId: string; }>) {
        this.pruneTime = new Date(maxBy(events, x => x.date).date).getTime() - PRUNE_SYNC_MIN_MS;
    }
}
