import { Component, Input, OnDestroy } from '@angular/core';
import { translateHeading } from '@weavix/domain/geometry/polygon';
import { LocationContributor } from '@weavix/models/src/location/location-update';
import { min } from 'lodash';
import { Person } from 'weavix-shared/models/person.model';
import { AccountService } from 'weavix-shared/services/account.service';
import { DvrControlsService } from 'weavix-shared/services/dvr-controls.service';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { css } from 'weavix-shared/utils/css';

declare const RichMarker: new (opts: any) => google.maps.Marker;

const TYPE_ICONS = {
    beacon: 'fa fa-crosshairs',
    network: 'fa fa-wifi',
    'unpinned-network': 'fa fa-wifi',
    survey: 'fa fa-compass',
};
const TYPE_COLORS = {
    beacon: css.colors.LT_BLUE,
    network: css.colors.GABBIE_GREEN,
    'unpinned-network': css.colors.MD_LT_GRAY,
    survey: css.colors.WARNING_YELLOW,
};

@Component({
    selector: 'app-location-details',
    templateUrl: './location-details.component.html',
    styleUrls: ['./location-details.component.scss'],
})
export class LocationDetailsComponent implements OnDestroy {
    @Input() person: Person;
    @Input() map: google.maps.Map;

    markers: google.maps.Marker[] = [];
    circles: google.maps.Circle[] = [];
    metric = false;

    constructor(
        private translationService: TranslationService,
        private dvrService: DvrControlsService,
        accountService: AccountService,
    ) { 
        this.metric = accountService.metric;
    }

    get hasLocationDevice(): boolean { return !!this.person.badge?.locationType && !!this.person.badge?.deviceType; }

    componentDidMount(): void {
    }

    ngOnDestroy() {
        this.markers.forEach(m => m.setMap(null));
        this.circles.forEach(m => m.setMap(null));
    }

    getDisplayLocationType(input: string) {
        switch (input) {
            case ('combain'):
            case ('openCellId'):
            case ('external'):
                return this.translationService.getImmediate('configuration.user.gps-assist-location');
            case ('beacon'): return this.translationService.getImmediate('configuration.user.beacon-location');
            case ('gps'): return this.translationService.getImmediate('configuration.user.gps-location');
            case ('network'): return this.translationService.getImmediate('configuration.user.network-location');
            case ('unpinned-network'): return this.translationService.getImmediate('configuration.user.unpinned-network-location');
            case ('cell'): return this.translationService.getImmediate('configuration.user.cell-location');
            case ('wisp'): return this.translationService.getImmediate('configuration.user.wisp-location');
            default: return input;
        }
    }

    getDisplayDeviceType() {
        if (this.person.badge?.locationType === 'wisp') return 'Wisp';
        if (this.person.badge?.deviceType === 'ios') return 'iOS';
        if (this.person.badge?.deviceType === 'android') return 'Android';
        return this.person.badge?.deviceType;
    }

    getLocationPermissions() {
        if (this.person?.badge?.locationType === 'wisp' || this.person?.badge?.deviceType === 'walt'
            || this.dvrService.inPlaybackMode) return '';
        const grantedPermissions = this.person?.badge?.grantedLocationPermissions;
        if ((!grantedPermissions?.fine && !grantedPermissions?.coarse)
                || !grantedPermissions?.background
                || grantedPermissions?.motion === false
                || grantedPermissions?.geofences === false) {
            return this.translationService.getImmediate('configuration.user.insufficient-location-permissions');
        }
    }

    get relativeDate() {
        return this.dvrService.inPlaybackMode ? this.dvrService.timestamp : new Date().getTime();
    }

    get movingDate() {
        return this.person?.badge?.locationDetails?.stationary ? new Date(this.person?.badge?.lastMoving) : new Date(this.relativeDate);
    }

    get inVehicleDate() {
        return this.person?.badge?.locationDetails?.inVehicle ? new Date(this.relativeDate) : new Date(this.person?.badge?.lastInVehicle);
    }

    isLocationDelayed() {
        return this.relativeDate - new Date(this.person?.badge?.locationDate).getTime() > 300000;
    }

    getLocationAccuracy(accuracy?: number) {
        const multiplier = this.metric ? 1 : 3.28084;
        return `${Math.ceil((accuracy ?? this.person.badge?.locationDetails?.accuracy) * multiplier)}${this.metric ? 'm' : 'ft'}`;
    }

    getSpeed(speed?: number) {
        const multiplier = this.metric ? 3.6 : 2.23694;
        return `${Math.round((speed ?? this.person.badge?.locationDetails?.speed) * 10 * multiplier) / 10}${this.metric ? 'kph' : 'mph'}`;
    }

    getHeading(heading: number = this.person.badge?.locationDetails?.heading) {
        if (heading == null) return '';
        return translateHeading(heading);
    }

    getContributorIcon(type: string) {
        switch (type) {
            case ('beacon'): return 'fa fa-crosshairs';
            case ('cell'): return 'fa fa-signal';
            case ('wifi'): return 'fa fa-wifi';
            case ('network'): return 'fa fa-wifi';
            case ('unpinned-network'): return 'fa fa-wifi';
            case ('snap'): return 'fa fa-lock';
            case ('peer'): return 'fa fa-user';
            case ('survey'): return 'fa fa-compass';
            default: return 'fa fa-compass';
        }
    }

    showContributors(location: [number, number], contributors: LocationContributor[], accuracy: number) {
        if (!this.map) return;
        this.markers.forEach(v => v.setMap(null));
        this.circles.forEach(v => v.setMap(null));
        this.markers = [];
        this.circles = [];

        contributors.forEach(contributor => {
            if (!contributor.location) return;

            const icon = TYPE_ICONS[contributor.type] ?? 'fa fa-signal';
            const color = TYPE_COLORS[contributor.type] ?? css.colors.RED;

            this.getMarker(color, icon, contributor.location);

            let distance = contributor.distance;
            if (contributor.contributors) {
                distance = min(
                    contributor.contributors.filter(c => c.distance && contributors.find(v => v.id === c.id)?.distance)
                        .map(c => Math.abs(c.distance - contributors.find(v => v.id === c.id)?.distance)),
                );
            }
            if (distance) {
                this.getCircle(color, contributor.location, distance);
            }
        });
        if (accuracy && location) {
            this.getMarker(css.colors.DK_GRAY, 'fa fa-map-marker', location);
            this.getCircle(css.colors.LT_BLUE, location, accuracy);
        }
    }

    private getMarker(color: string, icon: string, location: number[]) {
        const position = new google.maps.LatLng(Number(location[1]), Number(location[0]));
        const marker = new RichMarker({
            position,
            content: `
            <div class="map-marker">
                <div class="map-marker-icon-container fa-stack">
                    <i class="icon-map-marker fa-stack-2x fa-inverse border"></i>
                    <i class="icon-map-marker fa-stack-2x background" style="color: ${ color }"></i>
                    <i class="${ icon } fa-stack-1x fa-inverse icon" style="line-height: inherit; font-size: 26px"></i>
                </div>
            </div>`,
        });
        marker.setMap(this.map);
        this.markers.push(marker);
    }

    private getCircle(color: string, location: number[], radius: number) {
        const circle = new google.maps.Circle({
            fillColor: color,
            fillOpacity: 0.3,
            strokeColor: color,
            strokeWeight: 2,
        });
        circle.setCenter(new google.maps.LatLng(location[1], location[0]));
        circle.setRadius(radius);
        circle.setMap(this.map);
        this.circles.push(circle);
    }
}
