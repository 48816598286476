import { ComplexPersonCondition, ConditionalKeys, PersonFacilityPredicate, PersonLike, PersonMatcher, SimplePersonCondition } from '@weavix/models/src/person/person-matcher';
import { isEmpty, isNil } from 'lodash';

function matchesAtLeastOne(list: string[] | undefined, values: (string | undefined)[] | undefined): boolean {
    if (!list) return false;
    if (!values) return false;
    return list.some(listValue => values.includes(listValue));
}

export function conditionEvaluator(
    person: PersonLike,
    facilityLookup: PersonFacilityPredicate,
    facilityMatch = true,
    self?: PersonLike) {

    return (condition: SimplePersonCondition) => {
        if (!condition.value?.length && !condition.self) return null;

        // root level facility specification must match for any non-exact person match
        if (!facilityMatch && (condition.key !== ConditionalKeys.People || condition.negate)) return false;

        function evaluateStraight() {
            let value: string | string[] | undefined | PersonFacilityPredicate;
            let selfValue: string | string[] | undefined;
            switch (condition.key) {
                case ConditionalKeys.People: 
                    value = person.id;
                    selfValue = self?.id;
                    break;
                case ConditionalKeys.PersonCompany:
                    value = person.companyId;
                    selfValue = self?.companyId;
                    break;
                case ConditionalKeys.PersonCraft:
                    value = person.crafts;
                    selfValue = self?.crafts;
                    break;
                case ConditionalKeys.PersonTags:
                    value = person.tags;
                    selfValue = self?.tags;
                    break;
                case ConditionalKeys.PersonSites:
                    value = (facilityId: string) => {
                    return facilityLookup(facilityId, person)
                        || person.facilityIds?.includes(facilityId)
                        || !!person.facilities?.[facilityId];
                    };
                    selfValue = self?.facilityIds ?? Object.keys(self?.facilities ?? {});
                    break;
            }
            let values = condition.value ?? [];
            if (condition.self) {
                if (!selfValue) values = [];
                else if (Array.isArray(selfValue)) values = selfValue;
                else values = [selfValue];
            }
            if (isNil(value)) return false;
            if (typeof value === 'function') return values.some(v => (value as PersonFacilityPredicate)(v));
            if (Array.isArray(value)) return values.some(v => (value as string[]).includes(v));
            return values.some(v => value === v);
        }

        return evaluateStraight() ? !condition.negate : !!condition.negate;
    };
}

export function matchPerson(matcher: PersonMatcher, person: PersonLike, isPersonOnFacility: PersonFacilityPredicate = () => false, self?: PersonLike, falseOnNull?: boolean) {
    // Temporary support users should not be auto-added to channels.
    if (person.supportAccess) return false;
    if (self && !self.facilityIds && !self.facilities) {
        // developer error using this method without populating self
        throw new Error('Self matching requires facilityIds be populated');
    }
    
    function isFacilityMatch() {
        if (matcher.facilityIds && !isEmpty(matcher.facilityIds) && !matcher.facilityIds.some(facilityId => isPersonOnFacility(facilityId, person))) {
            return false;
        }
        if (matcher.excludedFacilityIds && !isEmpty(matcher.excludedFacilityIds) && matcher.excludedFacilityIds.some(facilityId => isPersonOnFacility(facilityId, person))) {
            return false;
        }
        return true;
    }

    const facilityMatch = isFacilityMatch();
    const evaluator = conditionEvaluator(person, isPersonOnFacility, facilityMatch, self);

    function advancedMatch(condition: ComplexPersonCondition): boolean | null {
        let ret: boolean | null = null;
        const search = condition.type === 'or';
        condition.conditions.forEach(v => {
            const evaluated = (v as SimplePersonCondition).key ? evaluator(v as SimplePersonCondition)
                : advancedMatch(v as ComplexPersonCondition);
            if (evaluated === null) return;

            if (ret === null) ret = evaluated;
            else if (evaluated === search) ret = search;
        });
        return ret;
    }
    
    if (matcher.advancedCondition?.enabled) {
        const advancedMatchResult = advancedMatch(matcher.advancedCondition); 
        if (advancedMatchResult === null && falseOnNull) return false;
        return advancedMatchResult ?? facilityMatch;
    }

    function standardMatch(conditions: SimplePersonCondition[]) {
        const includePerson = conditions.filter(condition => condition.key === ConditionalKeys.People && !condition.negate);
        const excludePerson = conditions.filter(condition => condition.key === ConditionalKeys.People && condition.negate);
        const generalConditions = conditions.filter(condition => condition.key !== ConditionalKeys.People);
        const standardMatchResult = advancedMatch({
            conditions: [
                ...excludePerson,
                {
                    conditions: [
                        ...includePerson,
                        {
                            conditions: generalConditions,
                            type: 'and',
                        },
                    ],
                    type: 'or',
                },
            ],
            type: 'and',
        });
        if (standardMatchResult === null && falseOnNull) return false;
        return standardMatchResult ?? facilityMatch;
    }

    // newer standard conditions require ALL conditions match even if exact people matches are included
    if (matcher.standardConditions) {
        return standardMatch(matcher.standardConditions);
    }

    function legacyMatch() {
        if (matchesAtLeastOne(matcher.excludedTags, person.tags) ||
            matchesAtLeastOne(matcher.excludedCrafts, person.crafts) ||
            matchesAtLeastOne(matcher.excludedCompanies, [person.companyId]) ||
            (!isEmpty(matcher.peopleCrafts) && !matchesAtLeastOne(matcher.peopleCrafts, person.crafts)) ||
            (!isEmpty(matcher.peopleTags) && !matchesAtLeastOne(matcher.peopleTags, person.tags)) ||
            (!isEmpty(matcher.companies) && !matchesAtLeastOne(matcher.companies, [person.companyId]))
        ) {
            return false;
        }
        return true;
    }

    function peopleMatch() {
        if (matchesAtLeastOne(matcher.excludedPeople, [person.id])) return false;

        if (!isEmpty(matcher.people)) {
            const personInList = matcher.peopleSet ? matcher.peopleSet.has(person.id!) : matcher.people?.includes(person.id!);
            if (person.id && personInList) return true;
            if (isEmpty(matcher.facilityIds) && isEmpty(matcher.peopleCrafts) && isEmpty(matcher.peopleTags) && isEmpty(matcher.companies)) return false;
        }
        return null;
    }

    // legacy format only used by ADCs
    return peopleMatch() ??
        (facilityMatch && legacyMatch());
}
