import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Facility } from '../models/facility.model';
import { FilterFolder, FilterFolderItem, Folder, FolderType, SelectionOptions } from '@weavix/models/src/folder/folder';
import { Resource } from '../models/folderized-data.model';
import { Utils } from '../utils/utils';
import { FacilityService } from './facility.service';
import { HttpService } from './http.service';
import { ProfileService } from './profile.service';


@Injectable({
    providedIn: 'root',
})
export class FolderService {
    constructor(
        private httpService: HttpService,
        private facilityService: FacilityService,
        private profileService: ProfileService,
    ) {}

    itemFolderMap: {[facilityId: string]: { [id: string]: Folder} };

    static getPath(folders: Folder[], childFolderId: string) {
        const folderPath: Folder[] = [];
        for (let folderId = childFolderId; folderId; ) {
            const folder = folders.find(f => f.id === folderId);
            if (!folder) break;
            folderPath.unshift(folder);
            folderId = folder.parentId;
        }
        return folderPath;
    }

    static removeFromTree(tree: FilterFolder, folderIdToRemove: string) {
        const folder = this.findInTree(tree, folderIdToRemove);
        const parentFolder = folder.parent;

        if (folder && parentFolder) {
            folder.id = parentFolder.id;
            parentFolder.children = folder.parent.children.filter(x => x.id !== folder.id);
        }

        if (folder && folder.items) {
            folder.items.forEach(i => {
                parentFolder.items.push({ ...i, folderId: parentFolder.id });
            });
        }

        return folder;
    }

    static addToTree(tree: FilterFolder, folderToAdd: FilterFolder, editable?: boolean) {
        const parentFolder = this.findInTree(tree, folderToAdd.parentId);
        if (parentFolder) {
            const folder = <FilterFolder>{ ...folderToAdd };
            folder.parent = parentFolder;
            folder.parentId = parentFolder.id;
            if (editable) folder.editable = editable;
            if (!parentFolder.children) parentFolder.children = [];
            parentFolder.children.push(folder);
            Utils.sortAlphabetical(parentFolder.children, (x) => x.name);
        }
        return folderToAdd;
    }

    static updateInTree(tree: FilterFolder, updatedFolder: FilterFolder) {
        const folder = this.findInTree(tree, updatedFolder.id);
        if (folder) {
            for (const x of Object.keys(updatedFolder)) {
                folder[x] = updatedFolder[x];
            }
            Utils.sortAlphabetical(folder.parent.children, (x) => x.name);
        }
        return folder;
    }

    static hasSelectedChild(tree: FilterFolder) {
        return tree.selected || (tree.children || []).some(x => this.hasSelectedChild(x));
    }

    static updateSelected(tree: FilterFolder, folderIdToSelect: string, folderItemIdToSelect?: string) {
        this.clearSelected(tree);

        const folder = this.findInTree(tree, folderIdToSelect);
        if (folder) {
            folder.selected = true;

            // Select folder item
            if (_.get(folder, 'items.length') > 0 && !folderItemIdToSelect) {
                folder.items[0].selected = true;
                folder.open = true;
            } else if (folderItemIdToSelect) {
                _.set(folder.items && folder.items.find(i => i.id === folderItemIdToSelect), 'selected', true);
                folder.open = true;
            }

            // Set parents to open
            let parent = folder.parent;
            while (parent) {
                parent.open = true;
                parent = parent.parent;
            }
        }
    }

    static getFolderPathDisplay(folderPath): Folder[] {
        if (folderPath.length > 3) {

            return [folderPath[0], {
                name: '...',
                id: folderPath[folderPath.length - 3].id,
                tooltip: folderPath.slice(1, folderPath.length - 2).map(f => f.name).join(' / '),
            }].concat(folderPath.slice(folderPath.length - 2));
        }

        let tooltip: string = '';
        return folderPath.map(f => {
            tooltip = tooltip.length ? `${tooltip} / ${f.tooltip || f.name}` : `/ ${f.tooltip || f.name}`;
            return {
                id: f.id,
                name: f.name.length > 24 ? `${f.name.substring(0, 24)}...` : f.name,
                tooltip: tooltip,
            };

        });
    }

    private static clearSelected(tree: FilterFolder) {
        tree.selected = false;
        (tree.items || []).forEach(i => i.selected = false);
        (tree.children || []).forEach(x => this.clearSelected(x));
    }

    private static findInTree(tree: FilterFolder, folderId: string): FilterFolder {
        if (tree.id === folderId) return tree;
        for (const x of (tree.children || [])) {
            const result = this.findInTree(x, folderId);
            if (result) return result;
        }
        return null;
    }

    url = (facilityId: string, type: FolderType, id?: string) => `/core/folders/${facilityId || 'root'}/${type}${id ? `/${id}` : ''}`;

    async getTree(component, type: FolderType, facilityId?: string, folderItems?: FilterFolderItem[], editable?: boolean): Promise<FilterFolder> {
        const tree: FilterFolder[] = await this.httpService.get(component, `${this.url(facilityId, type)}/tree`);
        const setParent = (folder: FilterFolder) => {
            (folder.children || []).forEach((child: FilterFolder) => {
                child.parent = folder;
                setParent(child);
            });
            folder.type = type;
            folder.children = Utils.sortAlphabetical(folder.children, (item) => item.name);
            folder.items = (folderItems || []).filter(i => i.folderId === folder.id);
            folder.editable = editable && folder.id !== undefined;
        };

        const root = <FilterFolder>{
            children: tree,
        };
        setParent(root);
        return root;
    }

    async getFolder(component, type: FolderType, id: string, facilityId?: string): Promise<Folder> {
        return await this.httpService.get(component, this.url(facilityId, type, id));
    }

    async getAll(component, type: FolderType, facilityId?: string): Promise<Folder[]> {
        return await this.httpService.get(component, this.url(facilityId, type));
    }

    async getChildren(component, type: FolderType, parentId: string = null, facilityId?: string): Promise<Folder[]> {
        return await this.httpService.get(component, this.url(facilityId, type), { parentId });
    }

    async create(component, type: FolderType, folder: Folder, facilityId?: string): Promise<Folder> {
        const result = await this.httpService.post<Folder>(component, this.url(facilityId, type), folder);
        this.setItemFolderMap(type, facilityId, folder);
        this.profileService.addFolder(result.id, folder.parentId);
        return result;
    }

    async update(component, type: FolderType, folder: Folder, facilityId?: string): Promise<Folder> {
        const result = await this.httpService.put<Folder>(component, this.url(facilityId, type, folder.id), folder);
        this.setItemFolderMap(type, facilityId, folder);
        return result;
    }

    async delete(component, type: FolderType, id: string, facilityId?: string) {
        const results = await this.httpService.delete<{ids: string[], folders: string[]}>(component, this.url(facilityId, type, id));
        results.folders.forEach(folder => {
            if (type === FolderType.Items && this.itemFolderMap && this.itemFolderMap[facilityId || 'root']) delete this.itemFolderMap[facilityId || 'root'][folder];
        });
        return results;
    }

    //#region Item Folder Helpers

    async buildItemFolderMap(component) {
        if (this.itemFolderMap) return;
        this.itemFolderMap = {};
        const facilities = await this.facilityService.getAll(component);
        facilities.map(x => x.id).concat('root').forEach(async (facId) => {
            const folders = await this.getAll(component, FolderType.Items, facId);
            this.itemFolderMap[facId] = <any>folders.reduce((a, b) => { a[b.id] = b; return a; }, {});
        });
    }

    /**
     * Gets the folder name for an (@see Item).
     * (@see buildItemFolderMap) is required to be called before this.
     */
    getItemFolderName(itemFolderId: string, itemFacilityId?: string) {
        if (!this.itemFolderMap) throw new Error('buildItemFolderMap must be called before use');
        const folder: Folder = _.get(this.itemFolderMap, `[${itemFacilityId || 'root'}][${itemFolderId}]`);
        return folder && folder.name || '';
    }

    getItemFolderPath(itemFolderId: string, itemFacilityId?: string, maxNameLength: number = 24) {
        if (!this.itemFolderMap) throw new Error('buildItemFolderMap must be called before use');
        const folders = Object.values(_.get(this.itemFolderMap, `[${itemFacilityId || 'root'}]`, {}));
        const pathFolders = FolderService.getPath(folders, itemFolderId);
        return pathFolders.map(x => x.name.length > maxNameLength ? `${x.name.substring(0, maxNameLength)}...` : x.name).join('/');
    }

    setItemFolderMap(type: FolderType, facilityId: string, folder: Folder) {
        if (!this.itemFolderMap) return;
        if (type === FolderType.Items) {
            const facility = facilityId || 'root';
            if (!this.itemFolderMap[facility]) this.itemFolderMap[facility] = {};
            this.itemFolderMap[facility][folder.id] = folder;
        }
    }

    //#endregion Item Folder Helpers

    async getAndBuildFolderPickerData(component, type: FolderType, selectionOptions: SelectionOptions, facilityId?: string) {
        const tree = await this.getTree(component, type, facilityId);
        return this.buildFoldersFromTree(tree, selectionOptions);
    }

    buildFoldersFromTree(tree: FilterFolder, selectionOptions: SelectionOptions) {
        const allFolders: Resource[] = [];
        const addFolder = (f: FilterFolder) => {
            allFolders.push({
                displayText: f.name,
                key: f.id,
                parentId: f.parentId || f.facilityId || null,
                data: f,
                isFolder: true,
                isFacility: f.isFacility,
                isSelectable: this.getIsSelectable(f, selectionOptions),
            });
            (f.children || []).forEach(addFolder);
        };
        tree.children.forEach(addFolder);
        return Utils.sortAlphabetical(allFolders, (item) => item.displayText);
    }

    buildFoldersFromSelection(folders: Folder[], facilities: Facility[], selectionOptions: SelectionOptions) {
        const allFolders: Resource[] = [];
        folders.forEach(f => {
            allFolders.push({
                displayText: f.name,
                key: f.id,
                parentId: f.parentId || f.facilityId || null,
                data: f,
                isFolder: true,
                isFacility: false,
                isSelectable: this.getIsSelectable(f, selectionOptions),
            });
        });
        facilities.forEach(f => {
            allFolders.push({
                displayText: f.name,
                key: f.id,
                data: f,
                isFolder: true,
                isFacility: true,
                isSelectable: selectionOptions.facilities ? true : false,
            });
        });
        return Utils.sortAlphabetical(allFolders, (item) => item.displayText);
    }

    getIsSelectable(f: FilterFolder, selectionOptions: SelectionOptions): boolean {
        if (f.isFacility) {
            return selectionOptions.facilities ? true : false;
        }
        return selectionOptions.folders ? true : false;
    }
}
